
<template>
  <div class="securityManagement">
    <!-- herd -->
    <div
      style="
        height: 10%;
        display: flex;
        align-items: center;
        margin-bottom: -1vh;
      "
      id="Title"
    >
      <div class="title-logo">
        <div class="logo">
          <img
            src="../../../assets/visualization/securityManagement/zklogo.png"
            alt=""
          />
        </div>
        <div class="title-text">
          <img
            src="../../../assets/visualization/securityManagement/security.png"
            alt=""
          />
        </div>
        <div class="title-msg">
          <!-- <div class="loadshow" @click="showPojList">
            <div style="margin-right: 10px; width: auto" class="herdStyle">
              当前项目:
            </div>
            <div class="dian1 hertaFont herdStyle" style="margin-right: 10px">
              测试数据123
            </div>
            <img
              class="pojImg"
              src="../../../assets/visualization/securityManagement/IMG_B.png"
              alt=""
            />
          </div> -->
          <div class="avueinputTree">
            <avue-input-tree
              default-expand-all
              v-model="deptId"
              placeholder="请选择所属部门"
              @change="chooseDept"
              :clearable="false"
              :props="inputTreeProps"
              :dic="nowUserDeptList"
            ></avue-input-tree>
          </div>
        </div>
      </div>
    </div>
    <!-- centent -->
    <div class="securityManagement_centent">
      <!-- 安全生产工时 -->
      <div class="center_Item">
        <div class="block-title">
          <div class="titleLogo">
            <img
              src="../../../assets/visualization/securityManagement/AQSCGS.png"
              alt=""
            />
          </div>
          <div class="titleText">安全生产工时</div>
          <img
            class="titleThoverHand"
            src="../../../assets/visualization/securityManagement/dian.png"
            alt=""
          />
        </div>
        <div class="main">
          <div class="table-main-blueLine" style="font-size: 0.7813vw">
            <div class="gunDong" id="AQSCsetInterval" ref="scrollContainer" v-show="AQSClist.length != '0'">
              <div
                v-for="(item, index) in AQSClist"
                :key="index"
                style="height: 4.6vh"
              >
                <span style="width: 13%; display: flex; align-items: center">
                  <img
                    src="../../../assets/visualization/securityManagement/dun.png"
                    alt=""
                  />
                </span>
                <span style="width: 40%" class="dian1">{{ item.orgName }}</span>
                <span style="width: 20%; text-align: right">
                  <span class="skyblueFont LED">{{ item.value }} </span> 天
                </span>
                <span style="width: 30%; text-align: right">
                  <span class="skyblueFont LED">{{ item.value1 }} </span> 工时
                </span>
              </div>
            </div>
            <div
              class="Nodataavailableatthemoment"
              v-show="AQSClist.length == '0'"
            >
              <img :src="emptyUrl" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 现场人员分布情况 -->
      <div class="center_Item">
        <div class="block-title">
          <div class="titleLogo">
            <img
              src="../../../assets/visualization/securityManagement/XCRYTJ.png"
              alt=""
            />
          </div>
          <div class="titleText">现场人员分布情况</div>
          <img
            class="titleThoverHand"
            src="../../../assets/visualization/securityManagement/dian.png"
            alt=""
          />
        </div>
        <div class="main" id="XCRYTJecharts"></div>
      </div>
      <!-- 安全隐患统计 -->
      <div class="center_Item">
        <div class="block-title">
          <div class="titleLogo">
            <img
              src="../../../assets/visualization/securityManagement/AQYHTJ.png"
              alt=""
            />
          </div>
          <div class="titleText">安全隐患统计</div>
          <img
            class="titleThoverHand"
            src="../../../assets/visualization/securityManagement/dian.png"
            alt=""
          />
        </div>
        <div class="main" id="AQYHTJecharts"></div>
      </div>
      <!-- AI智能分析 -->
      <div class="center_Item">
        <div class="block-title">
          <div class="titleLogo">
            <img
              src="../../../assets/visualization/securityManagement/AIZNFX.png"
              alt=""
            />
          </div>
          <div class="titleText">AI智能分析</div>
          <img
            class="titleThoverHand"
            src="../../../assets/visualization/securityManagement/dian.png"
            alt=""
          />
        </div>
        <div class="main" id="AIecharts"></div>
      </div>
      <!-- 班前会及交底 -->
      <div class="center_Item">
        <div class="block-title">
          <div class="titleLogo">
            <img
              src="../../../assets/visualization/securityManagement/BQH.png"
              alt=""
            />
          </div>
          <div class="titleText">班前会及交底</div>
          <img
            class="titleThoverHand"
            src="../../../assets/visualization/securityManagement/dian.png"
            alt=""
          />
        </div>
        <div class="main">
          <!-- 循环内部循环 -->
          <el-carousel
            height="24vh"
            interval="5000"
            indicator-position="none"
            arrow="never"
            v-show="BQJDHList.length > '0'"
          >
            <el-carousel-item v-for="(item, index) in BQJDHList" :key="index">
              <div
                class="elItem"
                v-for="(items, indexs) in item.data"
                :key="indexs"
              >
                <!-- ../../../assets/visualization/securityManagement/noImg.png -->
                <div class="elItemImageBox">
                  <img class="elItemImage" :src="items.filesUser" alt="" />
                </div>
                <div class="LBtxt">
                  <div>
                    <span
                      class="LBtitle"
                      style="color: #00f0ff; font-weight: 500"
                    >
                      {{ items.title }}
                    </span>
                    <br />
                    <span
                      class="dian2 LBcontet"
                      style="color: #fff; font-weight: 500"
                    >
                      {{ items.meetingContents }}
                    </span>
                  </div>
                  <div class="LBtime" style="color: #fff; font-weight: 500">
                    {{ items.meetingTime }}
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div
            class="Nodataavailableatthemoment"
            v-show="BQJDHList.length == '0'"
          >
            <img :src="emptyUrl" alt="" />
          </div>
        </div>
      </div>
      <!-- 今日作业统计 -->
      <div class="center_Item">
        <div class="block-title">
          <div class="titleLogo">
            <img
              src="../../../assets/visualization/securityManagement/JRZYTJ.png"
              alt=""
            />
          </div>
          <div class="titleText">今日作业统计</div>
          <img
            class="titleThoverHand"
            src="../../../assets/visualization/securityManagement/dian.png"
            alt=""
          />
        </div>
        <div class="main" v- id="JRZYTJecharts"></div>
      </div>
      <!-- 危险源、环境因素辨识 -->
      <div class="center_Item">
        <div class="block-title">
          <div class="titleLogo">
            <img
              src="../../../assets/visualization/securityManagement/WXY.png"
              alt=""
            />
          </div>
          <div class="titleText">危险源、环境因素辨识</div>
          <img
            class="titleThoverHand"
            src="../../../assets/visualization/securityManagement/dian.png"
            alt=""
          />
        </div>
        <div class="main" id="WXYecharets"></div>
      </div>
      <!-- 今日安全检查统计 -->
      <div class="center_Item">
        <div class="block-title">
          <div class="titleLogo">
            <img
              src="../../../assets/visualization/securityManagement/WXY.png"
              alt=""
            />
          </div>
          <div class="titleText">今日安全检查统计</div>
          <img
            class="titleThoverHand"
            src="../../../assets/visualization/securityManagement/dian.png"
            alt=""
          />
        </div>
        <div class="main" id="JRAQXCecharts"></div>
      </div>
      <!-- 项目动态 -->
      <div class="center_Item">
        <div class="block-title">
          <div class="titleLogo">
            <img
              src="../../../assets/visualization/securityManagement/XMDT.png"
              alt=""
            />
          </div>
          <div class="titleText">项目动态</div>
          <img
            class="titleThoverHand"
            src="../../../assets/visualization/securityManagement/dian.png"
            alt=""
          />
        </div>
        <div class="main">
          <div
            class="pojectNews"
            id="XMDTsetInterval"
            v-show="NodataavailableattheMoment"
          >
            <div
              v-for="item in XMDTlist"
              :key="item.ID"
              style="height: 3.5vh; font-size: 0.7813vw"
            >
              <img
                src="../../../assets/visualization/securityManagement/dongtai.png"
                alt=""
              />
              <span
                style="
                  width: 35%;
                  margin-left: 1.5vh;
                  color: #fff;
                  font-weight: 500;
                  font-size: 15px;
                "
              >
                {{ item.CREATEDATE }}
              </span>
              <span
                style="
                  width: 54%;
                  margin-left: 1.5vh;
                  color: #fff;
                  font-weight: 500;
                  font-size: 15px;
                "
                class="dian1"
              >
                {{ item.APANAGENAME }}
                {{ item.RECORDACTION }}
              </span>
            </div>
          </div>
          <div
            class="Nodataavailableatthemoment"
            v-show="!NodataavailableattheMoment"
          >
            <img :src="emptyUrl" alt="" />
          </div>
        </div>
      </div>
      <!-- 应用情况分析 -->
      <div class="center_Item">
        <div class="block-title">
          <div class="titleLogo">
            <img
              src="../../../assets/visualization/securityManagement/YYQK.png"
              alt=""
            />
          </div>
          <div class="titleText">应用情况分析</div>
          <img
            class="titleThoverHand"
            src="../../../assets/visualization/securityManagement/dian.png"
            alt=""
          />
        </div>
        <div
          class="main"
          v-show="NodataavailableattheMoment"
          id="YYQKFXecharts"
        ></div>
        <div
          class="Nodataavailableatthemoment"
          v-show="!NodataavailableattheMoment"
        >
          <img :src="emptyUrl" alt="" />
        </div>
      </div>
      <!-- 安全培训系统 -->
      <div class="center_Item">
        <div class="block-title">
          <div class="titleLogo">
            <img
              src="../../../assets/visualization/securityManagement/AQPXXT.png"
              alt=""
            />
          </div>
          <div class="titleText">安全培训系统</div>
          <img
            class="titleThoverHand"
            src="../../../assets/visualization/securityManagement/dian.png"
            alt=""
          />
        </div>
        <div class="main" id="AQPXXTecharts"></div>
      </div>
      <!-- 安全奖罚 -->
      <div class="center_Item">
        <div class="block-title">
          <div class="titleLogo">
            <img
              src="../../../assets/visualization/securityManagement/AQJF.png"
              alt=""
            />
          </div>
          <div class="titleText">安全奖罚</div>
          <img
            class="titleThoverHand"
            src="../../../assets/visualization/securityManagement/dian.png"
            alt=""
          />
        </div>
        <div class="main" id="AQJFecharts"></div>
      </div>
    </div>
    <!-- 遮罩层 -->
    <div @click.self="closePojList" class="pojList" v-show="pojListShow">
      <div class="hideBox">
        <el-input
          v-model="input"
          size="small"
          @input="inputSerch"
          placeholder="请输入项目名称"
        ></el-input>
        <div>
          <div class="scroll">
            <span
              class="hoverHand"
              @click="checkProject"
              v-for="item in pojListB"
              :id="item.CODE"
              :key="item.CODE"
            >
              <img
                class="oneChk"
                src="../../../assets/visualization/securityManagement/noChkPoj.png"
                alt=""
              />
              <img
                class="twoChk"
                style="display: none"
                src="../../../assets/visualization/securityManagement/isChkPoj.png"
                alt=""
              />
              {{ item.NAME }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  visualization,
  dbtbeforeclass,
  securityinspection,
} from "../../../api/visualization/index";
import { nowUserDept } from "@/api/user";
export default {
  components: {},
  data() {
    return {
      //安全生产工时
      AQSClist: [],
      // 项目动态
      XMDTlist: [
        {
          CREATEDATE: "2021-08-01",
          APANAGENAME: "安全生产班",
          RECORDACTION: "项目启动",
          ID: 1,
        },
        {
          CREATEDATE: "2021-08-01",
          APANAGENAME: "玉山南方",
          RECORDACTION: "项目启动",
          ID: 2,
        },
        {
          CREATEDATE: "2021-08-01",
          APANAGENAME: "玉峰铁山港",
          RECORDACTION: "项目启动",
          ID: 3,
        },
        {
          CREATEDATE: "2021-08-01",
          APANAGENAME: "尼日利亚",
          RECORDACTION: "项目启动",
          ID: 4,
        },
        {
          CREATEDATE: "2021-08-01",
          APANAGENAME: "叙利亚",
          RECORDACTION: "项目启动",
          ID: 5,
        },
        {
          CREATEDATE: "2021-08-01",
          APANAGENAME: "哎呦尼亚",
          RECORDACTION: "项目启动",
          ID: 6,
        },
      ],
      BQJDHList: [],

      pojListB: [
        {
          CODE: "1",
          NAME: "安全生产班",
        },
        {
          CODE: "2",
          NAME: "安全生产班qqq",
        },
        {
          CODE: "3",
          NAME: "安全生sadas产班",
        },
      ],
      pojListShow: false,
      input: "",
      pojList: [
        {
          CODE: "1",
          NAME: "安全生产班",
        },
        {
          CODE: "2",
          NAME: "安全生产班qqq",
        },
        {
          CODE: "3",
          NAME: "安全生sadas产班",
        },
      ],
      nowUserDeptListL: [],
      deptId: "1722789594114084866",
      companyId: "-1",
      inputTreeProps: {
        label: "title",
        value: "id",
      },
      nowYear: new Date().getFullYear(),
      emptyUrl: require("../../../assets/visualization/noData.png"),
      NodataavailableattheMoment: false,
    };
  },
  created() {},
  mounted() {
    let that = this;
    console.log(that.userInfo);
    nowUserDept().then((res) => {
      if (res.data.code === 200) {
        that.nowUserDeptList = res.data.data;
      }
    });
    that.bqhFunction();
    that.Safeproductionworkinghours();
    that.JRZYTJecharts();
    that.AIecharts();
    that.XCRYTJecharts();
    that.AQYHTJecharts();
    that.WXYecharets();
    that.JRAQXCecharts();
    // that.YYQKFXecharts();
    that.AQPXXTecharts();
    that.AQJFecharts();
    // that.openTimer("XMDTsetInterval");
  },
  beforeDestroy() {},
  // 计算属性
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    // 安全生产工时
    Safeproductionworkinghours() {
      let that = this;
      let arr = [];
      let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00142_Y&indexCodes=ZB00143_Y`;
      visualization(params).then((res) => {
        if (res.data.data.ZB00142_Y.length > 0) {
          res.data.data.ZB00142_Y.forEach((item, index) => {
            arr.push({
              orgName: item.orgName,
              value1: item.value,
              value: res.data.data.ZB00142_Y[index].value,
            });
          });
        }
        that.AQSClist = arr;
        if (that.AQSClist.length > 5) {
          const domElement = document.querySelector("#AQSCsetInterval");
          const everyJump = () => {
            domElement.style.transition = "margin-top 0.5s ease-in-out";
            domElement.style.marginTop = `${-1 * 4.6}vh`;

            domElement.addEventListener(
              "transitionend",
              () => {
                domElement.style.transition = "";
                domElement.style.marginTop = "0px";
                const firstChild = this.AQSClist.shift();
                that.AQSClist.push(firstChild);
                setTimeout(everyJump, 3000);
              },
              { once: true }
            );
          };
          everyJump();
        }

        // that.openTimer("AQSCsetInterval");
      });
    },
    // 班前会及交底
    bqhFunction() {
      let that = this;
      let data = {
        current: 1,
        size: 999,
        companyId: this.companyId,
        createDept: this.deptId,
      };
      dbtbeforeclass(data).then((res) => {
        res.data.data.records.forEach((item) => {
          let urs = JSON.parse(item.filesUser);
          item.filesUser = urs[0].link;
          item.meetingTime = item.meetingTime.split(" ")[0];
        });
        that.BQJDHList = that.transformData(res.data.data.records);
      });
    },
    // 班前会数据格式处理
    transformData(originalData) {
      let transformedData = [];
      let currentPage = { data: [] };
      for (let i = 0; i < originalData.length; i++) {
        if (i % 2 === 0) {
          if (currentPage.data.length > 0) {
            transformedData.push(currentPage);
            currentPage = { data: [] };
          }
          currentPage.data.push(originalData[i]);
        } else {
          currentPage.data.push(originalData[i]);
        }
      }
      if (currentPage.data.length > 0) {
        transformedData.push(currentPage);
      }
      return transformedData;
    },
    // 切换项目
    chooseDept(val) {
      this.deptId = val;
    },
    dtaChanges() {
      console.log(this.nowUserDeptList);
      this.nowUserDeptList.forEach((item) => {
        if (item.value == this.deptId) {
          this.companyId = item.companyId;
        }
      });
      this.bqhFunction();
      this.Safeproductionworkinghours();
      this.JRZYTJecharts();
      this.AIecharts();
      this.XCRYTJecharts();
      this.AQYHTJecharts();
      this.WXYecharets();
      this.JRAQXCecharts();
      // this.YYQKFXecharts();
      this.AQPXXTecharts();
      this.AQJFecharts();
    },
    // 今日作业统计
    JRZYTJecharts() {
      let that = this;
      var dom = document.getElementById("JRZYTJecharts");
      this.$echarts.init(dom).dispose();
      var myChart = this.$echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false,
        width: window.innerWidth / 4.3,
        height: window.innerHeight / 3.9,
      });
      let option;
      let data = [];
      let subtext = 0;
      let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00145_Y`;
      visualization(params).then((res) => {
        if (res.data.data.ZB00145_Y.length > 0) {
          res.data.data.ZB00145_Y.forEach((item) => {
            subtext += item.value;
            data.push({ name: item.orgName, value: item.value });
          });
          option = {
            title: {
              text: "作业总数",
              subtext: subtext,
              left: "49%",
              top: "center",
              textAlign: "center",
              textStyle: {
                color: "#fff",
                fontSize: that.fontSize(0.15),
              },
              subtextStyle: {
                color: "#FFF",
                fontSize: that.fontSize(0.15),
                fontWeight: "blod",
              },
            },
            series: [
              {
                name: "Nightingale Chart",
                type: "pie",
                radius: [that.fontSize(0.5), that.fontSize(0.8)],
                center: ["center", "center"],
                // roseType: 'area',
                itemStyle: {
                  borderRadius: 0,
                },
                lineStyle: { color: "#FFF" },
                labelLine: {
                  show: false,
                },
                label: {
                  formatter: "{b}" + " {c}次",
                  color: "#ffffff",
                  fontSize: 11,
                },
                // color: ['#ffb100', '#00c9af', '#00a0ff'],
                data: data,
                startAngle: 30, //旋转角度
              },
            ],
          };

          window.addEventListener("resize", myChart.resize);
          option && myChart.setOption(option);
        } else {
          option = that.echartsNoData();
          option && myChart.setOption(option);
        }
      });
    },
    // ai智能分析
    AIecharts() {
      let data1 = ['"铜川尧柏', "玉山南方项目部"];
      let data2 = ["", ""];
      let data3 = ["11", ""];
      let data4 = ["8", "4"];
      var dom = document.getElementById("AIecharts");
      this.$echarts.init(dom).dispose();
      var myChart = this.$echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false,
        width: window.innerWidth / 4.3,
        height: window.innerHeight / 4,
      });
      var Interval_YHWZGecharts = null;
      let arr = [];
      let option;
      if (arr.length > 0) {
        option = {
          legend: {
            right: "0",
            icon: "circle",
            textStyle: {
              color: "#FFF",
            },
            itemWidth: 10, // 设置宽度
            itemHeight: 10, // 设置高度
            // itemGap: 30 // 设置间距
          },
          tooltip: {},
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            axisLabel: {
              show: true,
              textStyle: {
                color: "#FFFFFF", //更改坐标轴文字颜色
                fontSize: this.fontSize(0.12), //更改坐标轴文字大小
              },
            },
            splitLine: {
              //去除网格线
              show: true,
              // color: "black"
              lineStyle: {
                color: "#4E548B", //更改刻度尺颜色
                type: "dashed", //'dotted'虚线 'solid'实线
              },
            },
          },
          yAxis: {
            type: "category",
            data: data1,
            axisTick: {
              show: false, //外刻度
              inside: true, //内刻度
              length: 5,
            },
            axisLabel: {
              show: true,
              // rotate: -15,
              textStyle: {
                color: "#FFFFFF", //更改坐标轴文字颜色
                fontSize: this.fontSize(0.12), //更改坐标轴文字大小
              },
              formatter: function (value) {
                var res = value;
                if (res.length > 5) {
                  res = res.substring(0, 4) + "..";
                }
                return res;
              },
            },
          },
          series: [
            {
              name: "未穿安全背心",
              type: "bar",
              stack: "total",
              barWidth: 15,
              label: {
                show: false,
              },
              emphasis: {
                focus: "series",
              },
              data: data2,
              label: {
                normal: {
                  show: true,
                  position: "insideRight",
                  formatter: "{c}",
                  color: "#ffffff",
                  fontSize: 10,
                },
              },
            },
            {
              name: "未系安全带",
              type: "bar",
              stack: "total",
              barWidth: 15,

              label: {
                show: false,
              },
              emphasis: {
                focus: "series",
              },
              data: data3,
              label: {
                normal: {
                  show: true,
                  position: "insideRight",
                  formatter: "{c}",
                  color: "#ffffff",
                  fontSize: 10,
                },
              },
            },
            {
              name: "未带安全帽",
              type: "bar",
              barWidth: 15,
              stack: "total",
              label: {
                show: false,
              },
              emphasis: {
                focus: "series",
              },
              data: data4,
              label: {
                normal: {
                  show: true,
                  position: "insideRight",
                  formatter: "{c}",
                  color: "#ffffff",
                  fontSize: 10,
                },
              },
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", myChart.resize);
      } else {
        option = this.echartsNoData();
        option && myChart.setOption(option);
      }
    },
    //现场人员将统计
    XCRYTJecharts() {
      let that = this;
      var dom = document.getElementById("XCRYTJecharts");
      this.$echarts.init(dom).dispose();
      var myChart = this.$echarts.init(dom);
      let data1 = [];
      let data2 = [];
      let data3 = [];
      let option;
      let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00144_Y`;
      visualization(params).then((res) => {
        if (res.data.data.ZB00144_Y.length > 0) {
          res.data.data.ZB00144_Y.forEach((item) => {
            data1.push(item.orgName);
            data2.push(item.value);
            data3.push(item.value2);
          });
          option = {
            legend: {
              textStyle: {
                color: "#fff",
              },
              right: "0",
            },
            grid: {
              left: "5%",
              right: "5%",
              bottom: "10%",
            },
            yAxis: {
              show: false,
              type: "value",
            },
            xAxis: {
              show: true,
              type: "category",
              data: [],
              axisLine: {
                show: true,
              },
              axisTick: {
                show: false, //外刻度
                inside: true, //内刻度
                length: 5,
              },
              axisLabel: {
                textStyle: {
                  color: "#fff",
                  // align: "left",
                  fontSize: this.fontSize(0.12),
                },
                formatter: function (params) {
                  var val = "";
                  if (params.length > 4) {
                    val = params.substr(0, 4) + "...";
                    // val = params.substr(0, 5) + '\n' + params.substr(4, params.length);
                    return val;
                  } else {
                    return params;
                  }
                },
              },
            },
            series: [
              {
                name: "内部人员",
                data: [],
                // stack: 'total',
                type: "bar",
                barWidth: "15",
                itemStyle: {
                  color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    {
                      offset: 0,
                      color: "#14d8f9",
                    },
                    {
                      offset: 1,
                      color: "#0285fc",
                    },
                  ]),
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: "{c}",
                  color: "#fff",
                  // position: 'insideLeft',
                  // offset: [fontSize(2.5), 0],
                },
              },
              {
                name: "外部人员",
                data: [],
                // stack: 'total',
                type: "bar",
                barWidth: "15",
                itemStyle: {
                  color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    {
                      offset: 0,
                      color: "#ffb708",
                    },
                    {
                      offset: 1,
                      color: "#fba980",
                    },
                  ]),
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: "{c}",
                  color: "#fff",
                },
              },
            ],
          };
          var Interval = null;
          clearInterval(Interval);
          InItOpen();
          Interval = setInterval(InItOpen, 3000);
          function InItOpen() {
            if (data1.length > 5) {
              option.xAxis.data = that.baseData(option, data1, 5);
              option.series[0].data = that.baseData(option, data2, 5);
              option.series[1].data = that.baseData(option, data3, 5);
            } else {
              option.xAxis.data = data1;
              option.series[0].data = data2;
              option.series[1].data = data3;
            }
            myChart.setOption(option);
          }
          window.addEventListener("resize", myChart.resize);
          option && myChart.setOption(option);
        } else {
          option = this.echartsNoData();
          option && myChart.setOption(option);
        }
      });
    },
    // 安全隐患统计
    AQYHTJecharts() {
      let that = this;
      var dataX = [];
      var data1 = [];
      var data2 = [];
      var data3 = [];
      var dom = document.getElementById("AQYHTJecharts");
      this.$echarts.init(dom).dispose();
      var myChart = this.$echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false,
      });
      let option;
      let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00147_Y`;
      visualization(params).then((res) => {
        let arr;
        if (res.data.data.ZB00147_Y.length > 0) {
          res.data.data.ZB00147_Y.forEach((item) => {
            dataX.push(item.orgName);
            data3.push(item.value);
            arr = item.value2.split(",");
            data1.push(arr[0]);
            data2.push(arr[1]);
          });
          option = {
            legend: {
              orient: "horizontal",
              // icon: 'roundRect',
              top: "5%",
              right: "6%",
              data: ["隐患总数", "已整改", "超期未整改"],
              itemWidth: 9, // 设置图例图形的宽
              itemHeight: 9,
              textStyle: {
                //图例文字的样式
                color: "#fff",
                fontSize: 11,
              },
            },
            grid: {
              left: "3%",
              right: "8%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: [],
              axisTick: {
                show: false, //外刻度
                inside: true, //内刻度
                length: 5,
              },
              axisLabel: {
                textStyle: {
                  color: "#FFFFFF", //更改坐标轴文字颜色
                  fontSize: this.fontSize(0.12), //更改坐标轴文字大小
                },
                formatter: function (value) {
                  var res = value;
                  if (res.length > 5) {
                    res = res.substring(0, 4) + "..";
                  }
                  return res;
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#002490", //更改坐标轴颜色
                },
              },
            },

            yAxis: {
              show: false,
              type: "value",
            },
            series: [
              {
                name: "超期未整改",
                type: "line",
                // stack: 'Total',
                data: [],
                itemStyle: {
                  normal: {
                    // barBorderRadius: [5, 5, 0, 0],
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#e6ac3e",
                        },
                        {
                          offset: 1,
                          color: "#ec7634",
                        },
                      ]
                    ),
                  },
                },
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "#fff",
                    fontSize: 10,
                  },
                },
              },
              {
                name: "已整改",
                smooth: true,
                type: "bar",
                barWidth: "15",
                // stack: 'Total',
                data: data2,
                itemStyle: {
                  color: "#9fe080",
                },
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "#fff",
                    fontSize: 10,
                  },
                },
              },
              {
                name: "隐患总数",
                type: "bar",
                barWidth: "15",
                // stack: 'Total',
                data: [],
                itemStyle: {
                  normal: {
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          // 四个数字分别对应 数组中颜色的开始位置，分别为 右，下，左，上。例如（1,0,0,0 ）代表从右边开始渐
                          // 变。offset取值为0~1，0代表开始时的颜色，1代表结束时的颜色，柱子表现为这两种颜色的渐变。
                          offset: 0,
                          color: "#4774db",
                        },
                        {
                          offset: 1,
                          color: "#4774db",
                        },
                      ]
                    ),
                  },
                },
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "#fff",
                    fontSize: 10,
                  },
                },
                // barGap: "-100%"
              },
            ],
          };
          var Interval = null;
          clearInterval(Interval);
          InItOpen();
          Interval = setInterval(InItOpen, 3000);
          function InItOpen() {
            if (data1.length > 5) {
              option.xAxis.data = that.baseData(option, dataX, 5);
              option.series[0].data = that.baseData(option, data1, 5);
              option.series[1].data = that.baseData(option, data2, 5);
              option.series[2].data = that.baseData(option, data3, 5);
            } else {
              option.xAxis.data = dataX;
              option.series[0].data = data1;
              option.series[1].data = data2;
              option.series[2].data = data3;
            }
            myChart.setOption(option);
          }
          window.addEventListener("resize", myChart.resize);
          option && myChart.setOption(option);
        } else {
          option = this.echartsNoData();
          option && myChart.setOption(option);
        }
      });
    },
    // 危险源、环境因素辨识
    WXYecharets() {
      let that = this;
      let category = [];
      let barData = [];
      let dom = document.getElementById("WXYecharets");
      this.$echarts.init(dom).dispose();
      let myChart = this.$echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false,
      });
      let option;
      let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00146_Y`;
      visualization(params).then((res) => {
        if (res.data.data.ZB00146_Y.length > 0) {
          res.data.data.ZB00146_Y.forEach((item) => {
            category.push(item.orgName);
            barData.push(item.value);
          });
          option = {
            grid: {
              top: "8%",
              left: "6%",
              right: "5%",
              bottom: "-2%",
              containLabel: true,
            },
            xAxis: {
              max: 150,
              type: "value",
              splitLine: {
                //坐标线
                show: false,
              },
              axisLabel: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              axisTick: {
                //外刻度
                show: false,
              },
            },
            yAxis: [
              {
                type: "category",
                splitLine: {
                  //坐标线
                  show: false,
                },
                axisLabel: {
                  //y内容
                  show: false,
                },
                axisLine: {
                  //y轴线
                  show: false,
                },
                axisTick: {
                  //外刻度
                  show: false,
                },
                data: [],
              },
              {
                type: "category",
                splitLine: {
                  //坐标线
                  show: false,
                },
                axisLabel: {
                  //y内容
                  show: true,
                  formatter: function (v) {
                    return "{a|" + v + "项}";
                  },
                  rich: {
                    a: {
                      fontSize: this.fontSize(0.16),
                      color: "#1f6acf",
                      //width: 290,
                      align: "right",
                    },
                  },
                },
                axisLine: {
                  //y轴线
                  show: false,
                },
                axisTick: {
                  //外刻度
                  show: false,
                },
                data: [],
              },
            ],
            series: [
              {
                //name: '2011年',
                type: "bar",
                silent: true,
                barWidth: 10,
                barGap: "-100%", // Make series be overlap
                data: [],
                itemStyle: {
                  normal: {
                    barBorderRadius: 7,
                    color: new this.$echarts.graphic.LinearGradient(
                      1,
                      0,
                      0,
                      0,
                      [
                        { offset: 0, color: "#1f6acf" },
                        { offset: 1, color: "#5adff8" },
                      ]
                    ),
                  },
                },
                label: {
                  normal: {
                    show: true,
                    position: "insideLeft",
                    formatter: "{b}\n\n\n",
                    textStyle: {
                      color: "#5adff8",
                      fontSize: this.fontSize(0.16), //文字的字体大小
                    },
                  },
                },
                z: 1,
              },
              {
                type: "bar",
                silent: true,
                barWidth: 10,
                data: [],
                itemStyle: {
                  normal: {
                    barBorderRadius: 5,
                    color: "rgba(179,226,255, 0.3)",
                  },
                },
                z: 0,
              },
            ],
          };

          var Interval = null;
          clearInterval(Interval);
          InItOpen();
          Interval = setInterval(InItOpen, 3000);
          function InItOpen() {
            var detaMax = 0;
            var dataJson = [];
            var detaMaxData = barData;
            for (var i = 0; i < detaMaxData.length; i++) {
              if (Number(detaMaxData[i]) > detaMax) {
                detaMax = Number(detaMaxData[i]);
              }
            }
            option.xAxis.max = detaMax;
            if (barData.length > 4) {
              option.yAxis[0].data = that.baseData(option, category, 4);
              option.yAxis[1].data = that.baseData(option, barData, 4);
              option.series[0].data = option.yAxis[1].data;
            } else {
              option.yAxis[0].data = category;
              option.yAxis[1].data = barData;
              option.series[0].data = barData;
            }
            for (var i = 0; i < barData.length; i++) {
              //如果最大值为0，则给数据默认赋值为1
              var MaxBarData = Math.max.apply(null, barData);
              if (MaxBarData == "0") {
                dataJson.push("1");
              } else {
                if (barData[i] == "0") {
                  dataJson.push(detaMax);
                } else {
                  dataJson.push("0");
                }
              }
            }
            option.series[1].data = dataJson;
            myChart.setOption(option);
          }
          window.addEventListener("resize", myChart.resize);
          option && myChart.setOption(option);
        } else {
          option = this.echartsNoData();
          option && myChart.setOption(option);
        }
      });
    },
    // 今日安全巡检统计
    JRAQXCecharts() {
      let that = this;
      var dom = document.getElementById("JRAQXCecharts");
      this.$echarts.init(dom).dispose();
      var myChart = this.$echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false,
        width: window.innerWidth / 4.3,
        height: window.innerHeight / 3.9,
      });
      let data = [];
      let num = 0;
      let option;
      let params = {
        createDept: this.deptId,
      };
      securityinspection(params).then((res) => {
        console.log("今日安全检查统计", res);
        if (res.data.data.length > 0) {
          res.data.data.forEach((item) => {
            num += item.count;
            data.push(item.count);
          });
          option = {
            title: {
              text: num,
              subtext: "巡查次数",
              left: "49%",
              top: "43%",
              textAlign: "center",

              textStyle: {
                color: "#00D89A",
                fontSize: this.fontSize(0.15),
              },
              subtextStyle: {
                color: "#FFF",
                fontSize: this.fontSize(0.15),
              },
            },
            series: [
              {
                name: "Nightingale Chart",
                type: "pie",
                radius: [this.fontSize(0.5), this.fontSize(0.8)],
                center: ["50%", "50%"],
                // roseType: 'area',
                itemStyle: {
                  borderRadius: 0,
                },
                lineStyle: { color: "#FFF" },
                labelLine: {
                  show: false,
                },
                label: {
                  formatter: "{b} {c}次",
                  color: "#ffffff",
                  fontSize: this.fontSize(0.12),
                },
                color: ["#ffb100", "#00c9af", "#00a0ff"],
                data: data,
                startAngle: 30, //旋转角度
              },
            ],
          };
          window.addEventListener("resize", myChart.resize);
          option && myChart.setOption(option);
        } else {
          option = this.echartsNoData();
          option && myChart.setOption(option);
        }
      });
    },
    // 应用情况分析
    YYQKFXecharts() {
      let that = this;
      let dom = document.getElementById("YYQKFXecharts");
      this.$echarts.init(dom).dispose();
      let myChart = this.$echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false,
      });
      let dataX = [
        "测试项目9",
        "测试数据11",
        "玉山南方",
        "玉峰铁山港",
        "水城海螺",
        "池州新材料第一标段",
      ];
      let data1 = ["31", "199", "66", "55", "188", "31"];
      let option;
      let arr = [];
      if (arr.length > 0) {
        option = {
          legend: {
            data: ["近一周应用次数"],
            textStyle: {
              color: "#FFF",
            },
            right: "0%",
            icon: "circle",
            itemWidth: 10, // 设置宽度
            itemHeight: 10, // 设置高度
          },
          grid: {
            top: "20%",
            bottom: "15%",
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: [],
            axisTick: {
              show: false, //外刻度
              inside: true, //内刻度
              length: 5,
            },
            axisLabel: {
              textStyle: {
                color: "#FFFFFF", //更改坐标轴文字颜色
                fontSize: this.fontSize(0.12), //更改坐标轴文字大小
              },

              formatter: function (value) {
                var res = value;
                if (res.length > 4) {
                  res = res.substring(0, 3) + "..";
                }
                return res;
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#002490", //更改坐标轴颜色
              },
            },
            splitLine: {
              //去除网格线
              show: false,
            },
          },
          yAxis: {
            type: "value",
            splitLine: {
              //去除网格线
              show: true,
              lineStyle: {
                color: "#4E548B", //更改刻度尺颜色
                type: "dashed", //'dotted'虚线 'solid'实线
              },
            },
            // splitArea: { show: false },//去除网格区域
            axisLabel: {
              show: true,
              textStyle: {
                color: "#FFFFFF", //更改坐标轴文字颜色
                fontSize: 10, //更改坐标轴文字大小
              },
            },
          },

          series: [
            {
              name: "近一周应用次数",
              data: [],
              type: "line",
              areaStyle: {},
              lineStyle: {},
              symbol: "circle",
              symbolSize: 5, //指示点大小
              smooth: 0.5, //弯曲程度
              areaStyle: {
                // 折现下是否填充
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(0,81,150,1)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(0,81,150,0.2)", // 100% 处的颜色
                    },
                  ],
                  global: false,
                },
              },
              label: {
                normal: {
                  show: true,
                  position: "top",
                  formatter: "{c}",
                  color: "#ffffff",
                  fontSize: 10,
                },
              },
            },
          ],
        };
        var Interval = null;
        clearInterval(Interval);
        InItOpen();
        Interval = setInterval(InItOpen, 3000);
        function InItOpen() {
          if (data1.length > 5) {
            option.xAxis.data = that.baseData(option, dataX, 5);
            option.series[0].data = that.baseData(option, data1, 5);
          } else {
            option.xAxis.data = dataX;
            option.series[0].data = data1;
          }
          myChart.setOption(option);
        }
        window.addEventListener("resize", myChart.resize);
        option && myChart.setOption(option);
      } else {
        option = this.echartsNoData();
        option && myChart.setOption(option);
      }
    },
    //安全培训系统
    AQPXXTecharts() {
      let that = this;
      let data1 = [];
      let data2 = []; // 培训人数
      let data3 = []; // 总人数
      let data4 = []; //合格率
      let dom = document.getElementById("AQPXXTecharts");
      this.$echarts.init(dom).dispose();
      let myChart = this.$echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false,
      });
      let option;
      let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00148_Y`;
      visualization(params).then((res) => {
        if (res.data.data.ZB00148_Y.length > 0) {
          res.data.data.ZB00148_Y.forEach((item) => {
            data1.push(item.orgName);
            data2.push(item.value);
            data3.push(item.value2);
            data4.push((item.value2 / item.value) * 100);
          });
          option = {
            legend: {
              icon: "circle",
              textStyle: {
                //fontWeight : 300 ,
                color: "#ffffff",
                fontSize: this.fontSize(0.13), //文字的字体大小
              },
              right: "7%",
              top: "2%",
              data: ["项目总人数", "培训人数"],
              itemWidth: 10, // 设置宽度
              itemHeight: 10, // 设置高度
              itemGap: 30, // 设置间距
            },
            grid: {
              left: "3%",
              right: "2%",
              bottom: "1%",
              top: "22%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data: [],
              boundaryGap: true,
              splitLine: {
                //坐标线
                show: false,
              },
              axisLabel: {
                show: true,
                rotate: -15,
                textStyle: {
                  color: "#FFFFFFs", //更改坐标轴文字颜色
                  fontSize: this.fontSize(0.12), //更改坐标轴文字大小
                },
                formatter: function (value) {
                  var res = value;
                  if (res.length > 5) {
                    res = res.substring(0, 4) + "..";
                  }
                  return res;
                },
              },
              axisLine: {
                lineStyle: {
                  color: "#4d548a", //更改坐标轴颜色
                },
              },
              axisTick: {
                show: false, //外刻度
                inside: true, //内刻度
                length: 5,
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                //坐标线
                show: true,
                lineStyle: {
                  color: "#4E548B", //更改刻度尺颜色
                  type: "dashed", //'dotted'虚线 'solid'实线
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#B9C8DB", //更改坐标轴颜色
                },
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#FFFFFF", //更改坐标轴文字颜色
                  fontSize: this.fontSize(0.12), //更改坐标轴文字大小
                },
              },
              axisTick: {
                //show: true,//外刻度
                inside: true, //内刻度
                length: 5,
              },
            },

            series: [
              {
                barGap: "20%" /*这里设置包含关系，只需要这一句话*/,
                barWidth: 15,
                data: data2,
                name: "培训人数",
                type: "bar",
                smooth: false, //关键点，为true是不支持虚线的，实线就用true
                showSymbol: true,
                symbolSize: 5, //设定实心点的大小
                hoverAnimation: false,
                itemStyle: {
                  normal: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#02efff",
                        },
                        {
                          offset: 1,
                          color: "#1385ff",
                        },
                      ]
                    ),
                  },
                },
                z: 1,
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "##fdb047",
                    fontSize: 10,
                  },
                },
              },
              {
                barWidth: 15,
                data: data3,
                stack: "总量",
                name: "项目总人数",
                type: "bar",
                showSymbol: true,
                hoverAnimation: false,
                itemStyle: {
                  normal: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#fcaa6e",
                        },
                        {
                          offset: 1,
                          color: "#feb51a",
                        },
                      ]
                    ),
                  },
                },
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "rgba(213, 219, 228,0.6)",
                    },
                    {
                      offset: 1,
                      color: "rgba(213, 219, 228,0.1)",
                    },
                  ]),
                },
                z: 2,
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "##fdb047",
                    fontSize: 10,
                  },
                },
              },
              {
                name: "百分比",
                barWidth: 15,
                data: data4,
                stack: "总量",
                type: "bar",
                showSymbol: true,
                hoverAnimation: false,
                itemStyle: {
                  normal: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: "rgba(0,0,0,0)",
                  },
                },
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: function (params, trigger) {
                      return (
                        "合格率:" +
                        Math.round(
                          (parseInt(data2[params.dataIndex]) /
                            parseInt(data3[params.dataIndex])) *
                            10000
                        ) /
                          100.0 +
                        "%\n"
                      );
                    },
                    color: "#02efff",
                    fontSize: 10,
                  },
                },
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "rgba(213, 219, 228,0.6)",
                    },
                    {
                      offset: 1,
                      color: "rgba(213, 219, 228,0.1)",
                    },
                  ]),
                },
                z: 0,
              },
            ],
          };

          var Interval = null;
          clearInterval(Interval);
          InItOpen();
          Interval = setInterval(InItOpen, 3000);
          function InItOpen() {
            if (data1.length > 5) {
              option.xAxis.data = that.baseData(option, data1, 5);
              option.series[0].data = that.baseData(option, data2, 5);
              option.series[1].data = that.baseData(option, data3, 5);
              option.series[2].data = that.baseData(option, data4, 5);
            } else {
              option.xAxis.data = data1;
              option.series[0].data = data2;
              option.series[1].data = data3;
              option.series[2].data = data4;
            }
            myChart.setOption(option);
          }
          window.addEventListener("resize", myChart.resize);
          option && myChart.setOption(option);
        } else {
          option = this.echartsNoData();
          option && myChart.setOption(option);
        }
      });
    },
    //安全奖罚
    AQJFecharts() {
      let that = this;
      var data1 = []; //x轴数据
      var data2 = [2, 2, 31, 88, 120, 55]; //奖励次数
      var data3 = [0, 12, 12, 66, 22, 33]; //罚款次数
      var dom = document.getElementById("AQJFecharts");
      this.$echarts.init(dom).dispose();
      var myChart = this.$echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false,
      });
      let option;
      let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00149_Y`;
      visualization(params).then((res) => {
        if (res.data.data.ZB00149_Y.length > 0) {
          res.data.data.ZB00149_Y.forEach((item) => {
            data1.push(item.orgName);
            data2.push(item.value);
            data3.push(item.value2);
          });
          option = {
            legend: {
              icon: "circle",
              textStyle: {
                //fontWeight : 300 ,
                color: "#ffffff",
                fontSize: 11, //文字的字体大小
              },
              right: "7%",
              top: "2%",
              data: ["奖励次数", "罚款次数"],
              itemWidth: 10, // 设置宽度
              itemHeight: 10, // 设置高度
              itemGap: 30, // 设置间距
            },
            tooltip: {
              trigger: "axis",
            },
            grid: {
              left: "3%",
              right: "2%",
              bottom: "1%",
              top: "22%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data: [],
              boundaryGap: true,
              splitLine: {
                //坐标线
                show: false,
              },
              axisLabel: {
                show: true,
                rotate: -15,
                textStyle: {
                  color: "#FFFFFFs", //更改坐标轴文字颜色
                  fontSize: this.fontSize(0.12), //更改坐标轴文字大小
                },
                formatter: function (value) {
                  var res = value;
                  if (res.length > 5) {
                    res = res.substring(0, 4) + "..";
                  }
                  return res;
                },
              },
              axisLine: {
                lineStyle: {
                  color: "#4d548a", //更改坐标轴颜色
                },
              },
              axisTick: {
                show: false, //外刻度
                inside: true, //内刻度
                length: 5,
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                //坐标线
                show: true,
                lineStyle: {
                  color: "#4E548B", //更改刻度尺颜色
                  type: "dashed", //'dotted'虚线 'solid'实线
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#B9C8DB", //更改坐标轴颜色
                },
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#FFFFFF", //更改坐标轴文字颜色
                  fontSize: this.fontSize(0.12), //更改坐标轴文字大小
                },
              },
              axisTick: {
                //show: true,//外刻度
                inside: true, //内刻度
                length: 5,
              },
            },

            series: [
              {
                barGap: "20%" /*这里设置包含关系，只需要这一句话*/,
                barWidth: 15,
                data: [],
                name: "奖励次数",
                type: "bar",
                smooth: false, //关键点，为true是不支持虚线的，实线就用true
                showSymbol: true,
                symbolSize: 5, //设定实心点的大小
                hoverAnimation: false,
                itemStyle: {
                  normal: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#02efff",
                        },
                        {
                          offset: 1,
                          color: "#1385ff",
                        },
                      ]
                    ),
                  },
                },
                z: 1,
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "##fcab6b",
                    fontSize: 10,
                  },
                },
              },
              {
                barWidth: 15,
                data: [],
                stack: "总量",
                name: "罚款次数",
                type: "bar",
                showSymbol: true,
                hoverAnimation: false,
                itemStyle: {
                  normal: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#fcaa6e",
                        },
                        {
                          offset: 1,
                          color: "#feb51a",
                        },
                      ]
                    ),
                  },
                },
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "rgba(213, 219, 228,0.6)",
                    },
                    {
                      offset: 1,
                      color: "rgba(213, 219, 228,0.1)",
                    },
                  ]),
                },
                z: 2,
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "##03e9ff",
                    fontSize: 10,
                  },
                },
              },
            ],
          };
          var Interval = null;
          clearInterval(Interval);
          InItOpen();
          Interval = setInterval(InItOpen, 3000);
          function InItOpen() {
            if (data1.length > 5) {
              option.xAxis.data = that.baseData(option, data1, 5);
              option.series[0].data = that.baseData(option, data2, 5);
              option.series[1].data = that.baseData(option, data3, 5);
            } else {
              option.xAxis.data = data1;
              option.series[0].data = data2;
              option.series[1].data = data3;
            }
            myChart.setOption(option);
          }
          window.addEventListener("resize", myChart.resize);
          option && myChart.setOption(option);
        } else {
          option = this.echartsNoData();
          option && myChart.setOption(option);
        }
      });
    },
    // 字体大小计算
    fontSize(res) {
      let docEl = document.documentElement,
        clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = 100 * (clientWidth / 1920);
      return res * fontSize;
    },
    // 数据处理
    baseData(option, name, size) {
      var NameData = [];
      var Data_Name = name;
      Data_Name.push(Data_Name[0]);
      Data_Name.splice(0, 1);
      for (var i = 0; i < Data_Name.length; i++) {
        NameData.push(Data_Name[i]);
        if (i + 1 == size) {
          break;
        }
      }
      return NameData;
    },
    // 是否开启滚动
    openTimer(name) {
      let that = this;
      switch (name) {
        case "XMDTsetInterval":
          if (that.XMDTlist.length <= 5) {
            return false;
          } else {
            that.xmdtToup("#XMDTsetInterval", 3000, 5);
          }
          break;
        case "AQSCsetInterval":
          if (that.AQSClist.length <= 5) {
            return false;
          } else {
            that.jumpToUp("#AQSCsetInterval", 3000, 4.6);
          }
          break;
      }
    },
    xmdtToup(dom, speed, goHeight) {
      const domElement = document.querySelector(dom);
      const everyJump = () => {
        domElement.style.transition = "margin-top 0.5s ease-in-out";
        domElement.style.marginTop = `${-1 * goHeight}vh`;
        domElement.addEventListener(
          "transitionend",
          () => {
            domElement.style.transition = "";
            domElement.style.marginTop = "0px";
            const firstChild = this.XMDTlist.shift();
            this.XMDTlist.push(firstChild);
            setTimeout(everyJump, speed);
          },
          { once: true }
        );
      };
      everyJump();
    },
    jumpToUp(dom, speed, goHeight) {
      const domElement = document.querySelector(dom);
      const everyJump = () => {
        domElement.style.transition = "margin-top 0.5s ease-in-out";
        domElement.style.marginTop = `${-1 * goHeight}vh`;

        domElement.addEventListener(
          "transitionend",
          () => {
            domElement.style.transition = "";
            domElement.style.marginTop = "0px";
            const firstChild = this.AQSClist.shift();
            this.AQSClist.push(firstChild);
            setTimeout(everyJump, speed);
          },
          { once: true }
        );
      };
      everyJump();
    },
    inputSerch() {
      var that = this;
      var input = that.input;
      var pojListB = [];

      pojListB = this.pojList.filter((item) => {
        if (input) {
          return item.NAME.toLowerCase().includes(input.toLowerCase());
        }
        return true;
      });

      this.pojListB = pojListB;
    },
    // 展示切换项目列表
    showPojList() {
      this.pojListShow = true;
    },
    // 切换项目
    checkProject(e) {},
    // 展示切换项目列表
    closePojList() {
      this.pojListShow = false;
    },
    // 无数据处理
    echartsNoData() {
      return {
        title: {
          text: "{B|}",
          left: "center",
          top: "center",
          textStyle: {
            rich: {
              B: {
                backgroundColor: {
                  image: require("../../../assets/visualization/noData.png"),
                },
                height: "100",
              },
            },
          },
        },
      };
    },
  },
  watch: {
    deptId(newValue, oldValue) {
      // 当someData变化时，这个函数将被调用
      this.dtaChanges();
    },
  },
};
</script>
<style lang="scss"  scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.securityManagement {
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 23, 86);
  position: relative;
}

.title-logo {
  display: flex;
  height: 7vh;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.title-logo div {
  width: 30%;
  display: flex;
  align-items: center;
}
.logo {
  height: 100%;
  padding-left: 3vh;
  position: relative;
}
.logo img {
  width: 12vw;
  height: auto;
}
.title-msg {
  height: 100%;
  padding: 0 1vh 0 0;
  display: flex;
  justify-content: flex-end;
}
.securityManagement_centent {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1vh;
}
.center_Item {
  width: 24.5%;
  height: 32%;
  background-image: url(../../../assets/visualization/securityManagement/itemBackground.png);
}
.block-title {
  background: linear-gradient(90deg, #0044ff3b 0%, rgba(0, 255, 255, 0) 100%);
  display: flex;
  font-size: 0.8854vw;
  height: 3vh;
  align-items: center;
}
.title-text {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.titleLogo {
  width: 16px;
  margin-left: 1vh;
  display: flex;
  height: 7vh;
  align-items: center;
  align-items: center;
}
.titleLogo img {
  width: 16px;
  height: 16px;
}
.titleText {
  flex-grow: 1;
  display: flex;
  align-items: center;
  color: #fff;
  margin-left: 15px;
  font-weight: 500;
  font-size: 15px;
}
.titleThoverHand {
  margin-right: 1vh;
  height: 70%;
}
.main {
  height: 90%;
  padding: 1vh;
  overflow: hidden;
}
.table-main-blueLine {
  height: 100%;
  overflow: hidden;
  padding: 0 1vh;
}
.table-main-blueLine > div > div {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #206ed4;
  height: 4vh;
  padding: 1vh;
  color: #fff;
}
@font-face {
  font-family: LED;
  src: url("../../../assets/fonts/LED/Digiface-Regular.ttf");
  unicode-range: U+30-39;
}
.LED {
  font-family: LED;
  color: #3cdcff;
}
.pojectNews {
  width: 100%;
  height: 100%;
}
.pojectNews > div {
  background-image: url(../../../assets/visualization/securityManagement/sanjiao.png);
  background-size: 100% 100%;
  margin-bottom: 1.5vh;
  display: flex;
  padding: 1vh;
  align-items: center;
}
::v-deep .el-carousel__item {
  padding: 1.5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.elItem {
  width: 100%;
  display: flex;
  height: 47%;
  justify-content: space-between;
}
.elItemImageBox {
  width: 40%;
}
.elItemImageBox img {
  width: 100%;
  height: 100%;
}
.LBtxt {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pojList {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1vh;
  z-index: 9;
  // box-sizing: border-box;
}
.hideBox {
  height: 91%;
  width: 17.1979vw;
  background-image: url(../../../assets/visualization/securityManagement/pojListBG.png);
  background-size: 100% 100%;
  float: right;
  margin-top: 9vh;
  padding: 1vh;
}
.hideBox > div:last-child {
  height: 95%;
  overflow: scroll;
  overflow-x: hidden;
}
.hideBox > div:last-child::-webkit-scrollbar {
  width: 4px;
}
.scroll {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.scroll > span {
  width: 16vw;
  padding: 1vh;
  display: flex;
  align-items: center;
  font-size: 1.5vh;
  color: white !important;
  font-weight: 400 !important;
}
.scroll > span img {
  margin-right: 1vh;
}
.scroll > span:hover {
  background-image: url(../../../assets/visualization/securityManagement/chkBG.png);
  background-size: 100% 100%;
  color: #1ed2ff !important;
}
.scroll > span:hover > .oneChk {
  display: none;
}
.scroll > span:hover > .twoChk {
  display: block !important;
}
::v-deep .el-input--small {
  margin: 0.5vh 0;
}
::v-deep .el-input__inner {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  background-image: url(../../../assets/visualization/securityManagement/pojIntBg.png);
  background-size: 100% 100%;
  color: #f1f1f1;
}
.loadshow {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: end !important;
}
.hertaFont {
  background-image: url(../../../assets/visualization/securityManagement/BTNIMG.png);
  background-size: 100% 100%;
  padding: 6px 18px;
}
.herdStyle {
  color: #5ad8eb;
  font-size: 2vh;
  // flex: 1;
}
.pojImg {
  width: 20px;
}
.avueinputTree {
  width: 50% !important;
}
.Nodataavailableatthemoment {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.Nodataavailableatthemoment img {
  height: 100px;
}
.dian2 {
  display: inline-block;
  width: 100%;
  // white-space: nowrap; /* 禁止换行 */
  // overflow: hidden; /* 超出部分隐藏 */
  // text-overflow: ellipsis; /* 显示省略号 */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 限制在两行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.dian1 {
  display: inline-block;
  width: 100%;
  // white-space: nowrap; /* 禁止换行 */
  // overflow: hidden; /* 超出部分隐藏 */
  // text-overflow: ellipsis; /* 显示省略号 */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* 限制在两行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
}
</style>
